import styled from 'styled-components';

export const PageContainer = styled.div`
  @media (min-width: 600px) {
    max-width: 98vw;
  }
  @media (min-width: 1024px) {
    max-width: 96vw;
  }
  @media (min-width: 1366px) {
    width: 76.281112737921%;
  }
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  position: relative;
`;
export const ContentWrapper = styled.div`
  text-align: center;
  margin: 2rem auto;
  padding-top: 11.875rem;
`;
export const NotFoundMessage = styled.h2`
  font-family: AlegreyaRegular;
  font-size: 2.4vw;
  line-height: 3.4375vw;
  font-weight: 300;
  @media (max-width: 1024px) {
    font-size: 3.5vw;
  }
  @media (max-width: 600px) {
    font-size: 6vw;
    line-height: 7.4375vw;
  }
  &.arabic_message {
    font-family: CairoRegular;
    padding-top: 20px;
    @media (max-width: 1024px) {
      padding-top: 30px;
    }
    @media (max-width: 600px) {
      padding-top: 10px;
    }
  }
`;
